import { useEffect, useRef } from 'react';
import gsap from 'gsap';

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Components
import Text from 'components/common/Text';
import Container from 'components/common/Container';
import Image from 'components/common/Image';
import LottieAnimation from 'components/common/LottieAnimation';

//* Helpers
import { letterSpacingAnimation } from 'helpers';
import scrollArrow from 'lottieFiles/scrollArrow.json';

//* Styles
import HeroSectionStyle from './style';

const HeroSection = ({ title, subtitle, image, left_image, right_image, isGatesActive, winWidth }) => {
	//! Refs
	const leftImage = useRef();
	const centerImage = useRef();
	const rightImage = useRef();
	const titleRef = useRef();
	const subtitleRef = useRef();
	const lottieContRef = useRef();

	//! Animation
	useEffect(() => {
		if (!isGatesActive) {
			gsap.to([leftImage.current, centerImage.current, rightImage.current], { duration: 1, y: '0', ease: 'power3.out' });
			gsap.to(subtitleRef.current, { duration: 1, opacity: '1', ease: 'power3.out' });
			letterSpacingAnimation(titleRef.current);
			gsap.to(lottieContRef.current, { delay: 0.5, scale: 1, opacity: 1 });
		}
	}, [isGatesActive, winWidth]);

	return (
		<HeroSectionStyle>
			{winWidth >= 768 ? (
				<Container
					row
					className='hero-container'>
					<div className='col-3 left-block'>
						<div
							ref={leftImage}
							className='image-block'>
							<Image
								src={left_image.src}
								alt={left_image.alt}
							/>
						</div>
					</div>

					<div className='col-6 center-block'>
						<div className='center-inner-block'>
							<Text
								ref={subtitleRef}
								className={'p p4 primary-color9 notoSans-regular hero-subtitle'}>
								{subtitle}
							</Text>

							<h1
								ref={titleRef}
								className='h3 primary-color9 minion3-regular hero-title'
								dangerouslySetInnerHTML={{ __html: title }}
							/>

							<div
								ref={centerImage}
								className='image-block'>
								<Image
									src={image.src}
									alt={image.alt}
								/>
							</div>
						</div>
					</div>

					<div className='col-3 right-block'>
						<div
							ref={rightImage}
							className='image-block'>
							<Image
								src={right_image.src}
								alt={right_image.alt}
							/>
						</div>
					</div>
				</Container>
			) : (
				<Container className={'hero-mobile-container'}>
					<Container
						row
						full
						className='hero-mobile-top-block'>
						<div className='col-6 mobile-left-block'>
							<div className='mobile-left-inner-block'>
								<div
									ref={leftImage}
									className='image-block'>
									<Image
										src={left_image.src}
										alt={left_image.alt}
									/>
								</div>
							</div>
						</div>

						<div className='col-6 mobile-right-block'>
							<div
								ref={rightImage}
								className='image-block'>
								<Image
									src={right_image.src}
									alt={right_image.alt}
								/>
							</div>
						</div>
					</Container>

					<div className='mobile-hero-bottom-block'>
						<div className='mobile-hero-bottom-inner-block'>
							<Text
								ref={subtitleRef}
								className={'p p4 primary-color9 notoSans-regular hero-mobile-subtitle'}>
								{subtitle}
							</Text>

							<h1
								ref={titleRef}
								className='h3 primary-color9 minion3-regular hero-mobile-title'
								dangerouslySetInnerHTML={{ __html: title }}
							/>

							<div
								ref={centerImage}
								className='image-block'>
								<Image
									src={image.src}
									alt={image.alt}
								/>
							</div>
						</div>
					</div>
				</Container>
			)}

			<LottieAnimation
				autoplay
				ref={lottieContRef}
				animData={scrollArrow}
				className='arrow-animation'
			/>
		</HeroSectionStyle>
	);
};

export default withUIContext(HeroSection, ['winWidth', 'isGatesActive']);
