import { memo as Memo, useMemo } from 'react';

//* HOC
import withDataContext from 'context/consumerHOC/DataConsumer';

//* Components
import Page from 'components/common/Page';
// import HeroSection from 'components/pages/home/HeroSection';
import AboutUsSection from 'components/pages/home/AboutUsSection';
import ProductsSliderSection from 'components/common/ProductsSliderSection';
import ProcessesSection from 'components/pages/home/ProcessesSection';
import CocktailsSection from 'components/common/CocktailsSection';

import HeroSectionNew from 'components/pages/home/HeroSectionNew';

//* Under Construction
// import Container from 'components/common/Container'
// import Text from 'components/common/Text'
// import Logo from 'components/common/Logo'
// import Button from 'components/common/Button'
// import Custom404Style from 'styles/pages/Custom404Style'

//* Style
import HomeStyle from 'styles/pages/HomeStyle';

const Home = Memo(({ pages, global, pageParams, config, selectedLang }) => {
	//! Global Data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang]);

	//! Page Data
	const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name]);

	return (
		<Page
			className={'home'}
			meta={pageData?.content?.meta}>
			{pageData && (
				<HomeStyle>
					<HeroSectionNew {...pageData.content.hero} />

					{/* <HeroSection {...pageData.content.hero} /> */}

					<AboutUsSection
						{...pageData.content.aboutUs}
						button={{ url: config.aboutUs.slug, text: 'readMore' }}
					/>

					{globalData && globalData?.popular_products && globalData?.popular_products.length > 0 && (
						<ProductsSliderSection
							title='popularProducts'
							products={globalData['popular_products']}
							button={{ url: config.products.slug, text: 'seeAllProducts' }}
						/>
					)}

					<ProcessesSection processes={pageData.content.progress} />

					{pageData.cocktails && pageData.cocktails.length > 0 && (
						<CocktailsSection
							title='cocktails'
							cocktails={pageData.cocktails}
							button={{ url: config.cocktails.slug, text: 'seeAllCocktails' }}
						/>
					)}
				</HomeStyle>
			)}
		</Page>
	);

	// return (
	//     <Page meta={{ title: 'Ohanyan | Under Construction' }} className="page-404">
	//         <Custom404Style>
	//             <Container className={'error-container'}>
	//                 <Logo className={'error-logo'} />
	//                 <div className={`content-wrap`}>
	//                     <Text
	//                         className={'h3 minion3-medium primary-color9 error-title'}
	//                         text={'COMING SOON'}
	//                     />
	//                     <Text
	//                         className={'p p2 notoSans-regular primary-color9 error-description'}
	//                         text={'UNDER CONSTRUCTION'}
	//                     />
	//                 </div>
	//             </Container>
	//         </Custom404Style>
	//     </Page>
	// )
});

export default withDataContext(Home, ['pages', 'global']);
