import styled from 'styled-components';

const HeroSectionStyle = styled.section`
	--hArrowWidth: var(--sp6x);
	--hArrowMarginTop: var(--sp13x);

	--centerImageWidth: 48.221%;
	--imageHeight: 133%;
	--imageBorderRadius: var(--sp3x);
	--heroSubtitleMBot: var(--sp2x);
	--centerBlockMTop: var(--sp27x);
	--centerImageMTop: var(--sp13x);
	--rightImageMTop: var(--sp18x);

	overflow: hidden;

	.hero-subtitle,
	.hero-title {
		text-align: center;
		opacity: 0;
		z-index: 1;
	}

	.hero-title {
		white-space: nowrap;
		letter-spacing: 1.1rem;
		text-transform: uppercase;
	}

	.hero-subtitle {
		margin-bottom: var(--heroSubtitleMBot);
	}

	.image-block {
		border-radius: var(--imageBorderRadius);
		overflow: hidden;
		isolation: isolate;
		backface-visibility: visible;

		transform: translateY(200%);

		.image-cont {
			--proportion: var(--imageHeight);
		}
	}

	.center-block {
		margin-top: var(--centerBlockMTop);

		.image-block {
			width: var(--centerImageWidth);
			margin-top: var(--centerImageMTop);
		}
	}

	.center-inner-block {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.right-block {
		margin-top: var(--rightImageMTop);
	}

	.arrow-animation.arrow-animation {
		width: var(--hArrowWidth);
		margin: var(--hArrowMarginTop) auto 0;
		transform-origin: top center;
		opacity: 0;
		transform: scale(0);

		svg {
			max-width: 100%;
		}
	}

	.hero-mobile-container {
		display: none;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--hArrowWidth: var(--sp5x);
		--hArrowMarginTop: var(--sp11x);

		--imageHeight: 133.67%;
		--centerImageWidth: 47.608%;
		--imageBorderRadius: var(--sp3x);
		--heroSubtitleMBot: var(--sp2x);
		--centerBlockMTop: var(--sp14x);
		--centerImageMTop: var(--sp13x);
		--rightImageMTop: var(--sp14x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--hArrowWidth: var(--sp5x);
		--hArrowMarginTop: var(--sp11x);

		--imageHeight: 142.67%;
		--centerImageWidth: 46.91%;
		--imageBorderRadius: var(--sp2x);
		--heroSubtitleMBot: var(--sp2x);
		--centerBlockMTop: var(--sp14x);
		--centerImageMTop: var(--sp7x);
		--rightImageMTop: var(--sp14x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp8x);

		--imageHeight: 142.764%;
		--centerImageWidth: 47.5%;
		--imageBorderRadius: var(--sp2x);
		--heroSubtitleMBot: var(--sp2x);
		--centerBlockMTop: var(--sp10x);
		--centerImageMTop: var(--sp5x);
		--rightImageMTop: var(--sp10x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp10x);

		--imageHeight: 142.54%;
		--centerImageWidth: 47.184%;
		--imageBorderRadius: var(--sp2x);
		--heroSubtitleMBot: var(--sp1x);
		--centerBlockMTop: var(--sp7x);
		--centerImageMTop: var(--sp8x);
		--rightImageMTop: var(--sp7x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp18x);

		--imageHeight: 142.86%;
		--centerImageWidth: 46.43%;
		--imageBorderRadius: var(--sp2x);
		--heroSubtitleMBot: var(--sp3x);
		--centerBlockMTop: var(--sp10x);
		--centerImageMTop: var(--sp8x);
		--rightImageMTop: var(--sp10x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp13x);

		--imageHeight: 155.34%;
		--centerImageWidth: 46.3%;
		--imageBorderRadius: var(--sp1x);
		--heroSubtitleMBot: var(--sp1x);
		--centerBlockMTop: var(--sp7x);
		--centerImageMTop: var(--sp6x);
		--rightImageMTop: var(--sp7x);
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp3x);

		--imageBorderRadius: var(--sp1x);

		.hero-container {
			display: none;
		}

		.hero-mobile-container {
			display: block;
		}

		.mobile-left-inner-block {
			margin-top: var(--sp16x);

			.image-block {
				width: 77.72%;

				.image-cont {
					--proportion: 135.3%;
				}
			}
		}

		.mobile-right-block {
			.image-block {
				.image-cont {
					--proportion: 133.72%;
				}
			}
		}

		.mobile-hero-bottom-block {
			margin-top: var(--sp3x);

			.image-block {
				width: 47.815%;
				margin-top: var(--sp2x);

				.image-cont {
					--proportion: 133.72%;
				}
			}
		}

		.hero-mobile-title {
			margin-top: var(--sp2x);
		}

		.mobile-hero-bottom-inner-block {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.hero-mobile-subtitle,
		.hero-mobile-title {
			text-align: center;
			z-index: 1;
			opacity: 0;
			opacity: 0;
		}

		.hero-mobile-title {
			white-space: nowrap;
			text-transform: uppercase;
		}
	}
`;

export default HeroSectionStyle;
